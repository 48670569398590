<template>
  <div>
    <div class="view-body">
      <div class="gq-news-body">
        <div class="gq-news-title-box wpbox">
          <iframe   src="https://can-achieve.pgh.partners/"></iframe>
        </div>
      </div>
    </div>
    <div class="gq-bottom-box">
      <gqIntroduction />
    </div>
  </div>
</template>
<script>
import gqIntroduction from "@/components/gq-introduction.vue";
export default {
  components: {
    gqIntroduction,
  },
};
</script>
<style lang="less" scoped>
.gq-news-title-box {
  margin-top: 60px;
  text-align: center;
.gq-news-title {
  font-size: 54px;
  font-weight: bold;
  color: #222222;
}
.gq-news-title-detail {
  margin: 0 auto;
  font-size: 15px;
  padding-top: 20px;
  font-weight: 300;
  color: #222222;
  width: 338px;
// border-bottom: 2px solid #648693;
  padding-bottom: 50px;
}
}
.gq-news-list-box {
  display: flex;
// justify-content: center;
  width: 1070px;
  overflow-x: hidden;
  margin: 0 auto;
  flex-wrap: wrap;
.gq-news-item {
  margin-left: 8px;
  margin-right: 8px;
}
}
.gq-news-phone-list-box {
  display: none;
}
.gq-news-top {
  display: none;
}
.gq-news-page-box {
  display: flex;
  justify-content: center;
  margin-top: 46px;
  margin-bottom: 67px;
}
/deep/ .btn-prev {
  width: 68px;
  height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
/deep/ .btn-next {
  width: 68px;
  height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
/deep/ .number {
  width: 38px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background: #ffffff;
}
/deep/ .active {
  background: linear-gradient(225deg, #62818e 0%, #81ccd9 100%);
}
.gq-news-title-box {
  margin-top: 60px;
  text-align: center;
}
.wpbox iframe {
  border: 0;
  width: 100%;
  height: 800px;
}
</style>